
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSpinner,
  IonIcon,
  IonButton,
  IonInput,
  modalController,
  menuController,
  IonButtons,
} from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, onMounted, computed } from "vue";

import { openToast } from "../services/toast";
import apiClienti from "../services/clienti";
import apiProgetti from "../services/progetti";

import ProjectDetail from "../components/ProjectDetail.vue";

export default {
  name: "Progetti",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonIcon,
    IonButton,
    IonButtons,
    IonInput,
  },
  setup() {
    const loading = ref(false);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;

    const openMenu = () => {
      menuController.open("app-menu");
    };

    const progetti = ref([]);

    const clienti = ref([]);
    const searchQuery = ref("");

    const searchedProgetti = computed(() => {
      const term = searchQuery.value.replace(/ /g, "");

      return progetti.value.filter((progetto) => {
        return (
          progetto.projects_name
            .toLowerCase()
            .replace(/ /g, "")
            .indexOf(term.toLowerCase().replace(/ /g, "")) != -1
        );
      });
    });

    /**
     * ! Limit customers to 30 charactes
     */
    function setShortName(projects_name) {
      if (!projects_name) {
        return "-";
      } else {
        const trimmedString = projects_name.length > 30 ? projects_name.substring(0, 27) + "..." : projects_name;
        return trimmedString;
      }
    }

    const riferimentoCliente = (cliente) => {
      if (cliente.customers_company) {
        return cliente.customers_company;
      } else if (cliente.customers_name && !cliente.customers_last_name) {
        return `${cliente.customers_name}`;
      } else if (!cliente.customers_name && cliente.customers_last_name) {
        return `${cliente.customers_last_name}`;
      } else if (cliente.customers_name && cliente.customers_last_name) {
        return `${cliente.customers_name} ${cliente.customers_last_name}`;
      }
      return ``;
    };

    /**
     * Badge stato progetto
     */
    const statoProgetto = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 1) {
          //da schedulare
          className = "badge_open";
        } else if (statusId == 2) {
          //in corso
          className = "badge_in_progress";
        } else if (statusId == 3) {
          //in pausa
          className = "badge_pause";
        } else if (statusId == 4) {
          //cancellato
          className = "badge_canceled";
        } else if (statusId == 5) {
          //completato
          className = "badge_completed";
        }
        return className;
      };
    });

    /**
     * Open project detail page
     */
    async function openProject(project) {
      const modal = await modalController.create({
        component: ProjectDetail,
        componentProps: {
          data: project,
        },
      });
      return modal.present();
    }

    /**
     * Get all clienti
     */
    async function loadClienti() {
      loading.value = true;
      try {
        const res = await apiClienti.getCustomers();
        if (res.status === 0) {
          clienti.value = res.data;
        } else {
          openToast("Errore durante la richiesta dei clienti", "toast_danger");
        }
      } catch (error) {
        clienti.value = [];
        openToast("Errore durante la richiesta dei clienti", "toast_danger");
      } finally {
        loading.value = false;
      }
    }

    async function loadProgetti() {
      loading.value = true;
      try {
        const res = await apiProgetti.getCommesse();
        if (res.status === 0) {
          progetti.value = res.data;
        } else {
          openToast("Errore durante la richiesta dei progetti", "toast_danger");
        }
      } catch (error) {
        clienti.value = [];
        openToast("Errore durante la richiesta dei progetti", "toast_danger");
      } finally {
        loading.value = false;
      }
    }

    onMounted(() => {
      //GET CLIENTI DATA
      loadClienti();
      loadProgetti();
    });

    return {
      loading,
      add,
      refresh,
      openProject,
      loadClienti,
      clienti,
      searchQuery,
      searchedProgetti,
      riferimentoCliente,
      menu,
      openMenu,
      //progetti
      progetti,
      loadProgetti,
      statoProgetto,
      setShortName,
    };
  },
};
